.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.ant-input-affix-wrapper > input.ant-input{
  padding-left: 3px;
}
.search-right {
  float: right;
  margin-bottom: 8px;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 28px;
  line-height: 11px;
}
.support-avatar {
  height: "70px" !important;
  width: "70px" !important;
}
.main-wrapper {
  height: 90vh;
  margin: 10px 10px;
  /*filter: drop-shadow(-1px 5px 3px #ccc);*/
}
.info-wrapper {
  margin-top: 15px;
}
.map-details {
  text-align: center;
  font-size: 1.2em;
}
.map-details span {
  font-weight: bold;
}
.search-input {
  font-size: 1.2em;
  width: 80%;
}
.location-map .nav-search-input:not(.is-mobile){
  min-width: 0!important;
}
.add-ride .nav-search-input:not(.is-mobile){
  min-width: 0!important;
}
.add-ride .ant-input-disabled {
  color: #d0d4d7;
  background-color: #f7f7f8;
  cursor: not-allowed;
  opacity: 1;
}
.ant-table-content{
  overflow: hidden;
  overflow-x: auto;
}

.table-action-wrapper {
  white-space: nowrap;
}

.cm-main-head{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

@media(max-width:767.98px){
  .cm-main-head h3,
  .head-action-block{
    width: 100%;
  }
}

.total-booking{
  /* border: 1px solid; */
    width: 80%;
    height: 80%;
    margin-left: 20px;
    margin-top: 42px;
    align-items: center;
    /* justify-content: center; */
    display: flex;
    flex-direction: column;
}
.total-booking div{
  color: #3e79f7;
  font-size:xx-large;
  padding: 30px;
}